const state = {
	keepLivePage: 'layout'
}

const mutations = {
	ADD_KEEP: (state, page) => {
		state.keepLivePage += `,${page}`
	},
	REMOVE_KEEP: (state, page) => {
		let keep_arr = state.keepLivePage.split(',')
		state.keepLivePage = keep_arr.filter(k => k !== page).join(',')
	}
}

const actions = {
	addKeep ({ commit }, page) {
		commit('ADD_KEEP', page)
	},
	removeKeep ({ commit }, page) {
		commit('REMOVE_KEEP', page)
	}
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}