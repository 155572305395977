import { h, createVNode } from 'vue'
import { notification, message, Modal } from 'ant-design-vue'
import { SmileOutlined, FrownOutlined, InfoCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'

const andtMixin = {
	methods: {
		// 通知提示框
		// status: success warn fail
		notice (title='标题1', desc="123", status='succe1ss') {
			notification.open({
				message: title,
				description: desc,
				icon: h(
					status === 'success' ? SmileOutlined :  status === 'fail' ? FrownOutlined : InfoCircleOutlined, 
					{ style: status === 'success' ? 'color: #108ee9' : status === 'fail' ? 'color: red' : 'color: orange'},
				),
			})
		},
		msg_success (content='提示', duration=3) {
			message.success(content, duration)
		},
		msg_warn (content='提示', duration=3) {
			message.warn(content, duration)
		},
		msg_error (content='提示', duration=3) {
			message.error(content, duration)
		},
		msg_loading (content='提示', duration=3) {
			message.loading(content, duration)
		},
		showConfirm (title='提示', content='提示内容', ok) {
			Modal.confirm({
				title: () => title,
				icon: () => createVNode(ExclamationCircleOutlined),
				content: () => createVNode('div', { style: 'color:red;' }, content),
				okText: () => '确认',
				cancelText: () => '取消',
				onOk:() => {
					ok()
        },
				onCancel() {
					// console.log('Cancel');
					// this.close()
				},
				class: 'test',
			});
		}
	}
}
export default andtMixin