
// import { defineComponent, computed } from 'vue'
// import { useStore } from 'vuex'
// export default defineComponent({
// 	setup () {
// 		const store = new useStore()
// 		const includes = computed({
// 			get () {
// 				return store.state.keepAlive.keepLivePage
// 			},
// 			set () {}
// 		})
// 		return {
// 			includes
// 		}
// 	}
// })
