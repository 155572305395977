import { createRouter, createWebHashHistory  } from "vue-router"
// 1. 定义路由组件.
// 也可以从其他文件导入
const Login = () => import('../views/login/login')
const Dashboard = () => import('../views/dashboard/index')
// const Block = () => import('../views/dashboard/block')
const Setting = () => import('../views/setting/index')
const Table = () => import('../views/table/index')
const TableUpdate = () => import('../views/table/update')
const DailyAnalysis = () => import('../views/daily/analysis')
const DailyReport = () => import('../views/daily/report')
const StatisticsCustomerClass = () => import('../views/statistics/customer-class')
const StatisticsArea = () => import('../views/statistics/area')
const StatisticsCycle = () => import('../views/statistics/cycle')
const StatisticsSource = () => import('../views/statistics/source')
const StatisticsFollow = () => import('../views/statistics/follow')
const StatisticsCollectionRate = () => import('../views/statistics/collection-rate')
const StatisticsArrears = () => import('../views/statistics/arrears')
const StatisticsCost = () => import('../views/statistics/cost')
// const Demo = () => import('../views/demo')
const routes = [
	{path: '/', component: Dashboard},
	{path: '/login', component: Login},
	{path: '/dashboard', component: Dashboard},
	{path: '/setting', component: Setting},
	{path: '/table-index', component: Table},
	{path: '/table-update', component: TableUpdate},
	{path: '/daily-analysis', component: DailyAnalysis},
	{path: '/daily-report', component: DailyReport},
	{path: '/statistics/customer-class', component: StatisticsCustomerClass},
	{path: '/statistics/area', component: StatisticsArea},
	{path: '/statistics/cycle', component: StatisticsCycle},
	{path: '/statistics/source', component: StatisticsSource},
	{path: '/statistics/follow', component: StatisticsFollow},
	{path: '/statistics/collection-rate', component: StatisticsCollectionRate},
	{path: '/statistics/arrears', component: StatisticsArrears},
	{path: '/statistics/cost', component: StatisticsCost},
	
]

const history = createWebHashHistory()

export default createRouter({
	history,
	routes
})