import request from './axios'

export const selectUserDom = () => request.post('/user')
export const selectUserData = () => request.post('/userData')
export const selectUserForm = () => request.post('/userForm')

//登录
export const login = (params) => request.post('/login/signIn', params)
// 登录页背景图
export const loginBgImg = () => request.post('/system/options/getSingInBackImg', {})
// 框架页
export const selectLayoutTopNav = () => request.post('/system/getTopMenu', {})
export const selectLayoutLeftMenuByNavId = (nav_id) => request.post('/system/getLeftMenu', {nav_id})
// 仪表盘接口
export const selectStatisticReport = (params) => request.post('/statisticReport/index', params)
// 通用table Form DOM渲染
export const selectTableDomByPageId = (page_id, extend, callback) => {
	let data = { page_id }
	for (let i in extend) {
		data[i] = extend[i]
	}
	request.post('/form/table', data).then(callback)
}
// 通用form表单接口
export const selectFormDomByPageId = (page_id, id, type, extend, callback) => {
	let data = {}
	id ? data = {page_id, id, type} : data = {page_id, type}
	for (let i in extend) {
		data[i] = extend[i]
	}
	request.post('/form/form', data).then(callback)
}
// 通用table增删改查
export const selectTableListData = (page_id, page, pageSize, searchs, extend, callback) => {
	let data = {page_id, page, pageSize, searchs}
	for (let i in extend) {
		data[i] = extend[i]
	}
	request.post('/data/index', data).then(callback)
}
export const createTableData = (page_id, form, extend, callback) => {
	let data = { page_id, form }
	for (let i in extend) {
		data[i] = extend[i]
	}
	request.post('/data/store', data).then(callback)
}
export const selectTableRowDetailById = (page_id, id, extend, callback) => {
	let data = { page_id, id }
	for (let i in extend) {
		data[i] = extend[i]
	}
	request.post('/form/detail', data).then(callback)
}
export const selectDetailById = (page_id, id, extend, callback) => {
	let data = { page_id, id }
	for (let i in extend) {
		data[i] = extend[i]
	}
	request.post('/data/show', data).then(callback)
}
export const deleteTableRowDataById = (page_id, id, extend, callback) => {
	let data = { page_id, id }
	for (let i in extend) {
		data[i] = extend[i]
	}
	request.post('/data/destory', data).then(callback)
}
export const updatetableRowDataById = (page_id, id, form, extend, callback) => {
	let data = { page_id, id, form }
	for (let i in extend) {
		data[i] = extend[i]
	}
	request.post('/data/update', data).then(callback) 
}
// 通用上传
export const upload = (page_id, base64File, name) => request.post('/data/upload', {page_id, base64File, name})

// 通用按钮点击后访问的接口
export const pluginButton = (url, id) => request.post(url, { id })
export const pluginInterfaceByParams = (url, params) => request.post(url, params)
export const pluginInterfaceAndDownLoad = (url, params) => request.download(url, params)


// 卓越单独页面接口
// 销售日志
export const zyDailyReport = (params) => request.post('/plugin/477/1284/saleLogTable', params)
// 销售分析 获取工作分类
export const zyDailyAnalysisType = () => request.post('/plugin/477/1284/getWorkType', {})
// 销售分析
export const zyDailyAnalysis = (params) => request.post('/plugin/477/1284/saleLogAnalysis', params)

export const exportFile = (url,id,file,callback) => {
	let formData = new FormData()
	formData.append('file', file)
	formData.append('app_id', 466)
	if(id > 0){
		formData.append('detail_id', id)
	}
	request.upload(url, formData).then(callback)
}


// 仪表盘
// 事项提醒接口
export const sxtxData = (data) => request.post('/home/home', data)
// 部门数据
export const bigDepartmentData = () => request.post('/statisticalReport/bigDepartment', {})
// 客户来源下拉菜单
export const khlySelect = (data) => request.post('/statisticalReport/getSelectDict', data)
// 客户来源统计数据
export const echartData = (data) => request.post('/statisticalReport/index', data)
// 获取搜索条件的下拉菜单数据
export const getSelectDict = (data) => request.post('/statisticalReport/getSelectDict', data)
// 修改密码
export const changePassword = (data) => request.post('/sysUser/changePassword', data)
// 获取区域下拉菜单
export const getSelectArea = () => request.post('/statisticalReport/getArea', { type: 'province'})
// 根据部门id获取人员信息
export const getSelectSale = (org_id) => request.post('/statisticalReport/getSale', { org_id })
// 查询是否存在客户
export const getSearchUser = (data) => request.post('/plugin/466/1256/selectUser', data)

