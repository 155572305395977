import { toRaw } from '@vue/reactivity'

const state = {
	username: '',
	token: null,
	navActive: null,
	sideBar: false,
	tabs: [{path: '/', title: '仪表盘', index: 1, closable: false}],
	tabActive: 1,
	showModal: false,
	isOa: 0,
	oaToken: null
}

const mutations = {
	USERNAME: (state, username) => {
		state.username = username
	},
	SET_TOKEN: (state, token) => {
		state.token = token
	},
	SET_OA_TOKEN: (state, token) => {
		state.oaToken = token
	},
	CHANGE_NAV_ACTIVE: (state, active) => {
		state.navActive = active
	},
	TOGGLE_SIDEBAR: state => {
		state.sideBar = !state.sideBar
	},
	ADD_TAB: (state, tab) => {
		if (tab && toRaw(state.tabs).findIndex(item => item.title === tab.title) === -1) {
			state.tabs = [...toRaw(state.tabs), tab]
		}
		state.tabActive = toRaw(state.tabs).filter(item => item.title === tab.title)[0].index
	},
	REMOVE_TAB: (state, tabIndex) => {
		let lastIndex = 0
		let tabs = toRaw(state.tabs)
		tabs.map((t, i) => { if (t.index === tabIndex) lastIndex = i - 1 })
		let removed = tabs.filter(t => t.index !== tabIndex)
		if (state.tabActive === tabIndex) {
			console.log(tabs)
			console.log(lastIndex)
			state.tabActive = tabs[lastIndex].index
		}
		state.tabs = removed
	},
	SWITCH_TAB: (state, tabIndex) => {
		state.tabActive = tabIndex
	},
	CLEAR_TAB: (state) => {
		state.tabs = [{path: '/', title: '仪表盘', index: 1, closable: false}]
		state.tabActive = 1
	},
	CHANGE_MODAL_STATUS: (state, status) => {
		state.showModal = status
	},
	IS_OA: (state, status) => {
		state.isOa = status
	}
}

const actions = {
	username({commit}, username) {
		commit('USERNAME', username)
	},
	setToken ({commit}, token) {
		commit('SET_TOKEN', token)
	},
	setOaToken ({commit}, token) {
		commit('SET_OA_TOKEN', token)
	},
	changeNavActive ({commit}, active) {
		commit('CHANGE_NAV_ACTIVE', active)
	},
	toggleSideBar ({ commit }) {
		commit('TOGGLE_SIDEBAR')
	},
	addTab ({commit}, tab) {
		commit('ADD_TAB', tab)
	},
	removeTab ({commit}, tabIndex) {
		commit('REMOVE_TAB', tabIndex)
	},
	switchTab ({commit}, tabIndex) {
		commit('SWITCH_TAB', tabIndex)
	},
	clearTab ({ commit} ) {
		commit('CLEAR_TAB')
	},
	showModal ({commit}, status) {
		commit('CHANGE_MODAL_STATUS', status)
	},
	isOA ({commit}, status) {
		commit('IS_OA', status)
	}
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}