const eventList = {};

const $on = function (eventName, callback) {
  if (!eventList[eventName]) {
    eventList[eventName] = [];
  }
  eventList[eventName].push(callback);
};

const $off = function (eventName, callback) {
  if (eventList[eventName]) {
    if (callback) {
      const index = eventList[eventName].indexOf(callback);
      eventList[eventName].splice(index, 1);
    } else {
      eventList[eventName].length = 0;
    }
  }
};

const $emit = function (eventName, ...params) {
  if (eventList[eventName]) {
    const fnArr = eventList[eventName];
    fnArr.forEach((callback) => {
      callback(...params);
    });
  }
};

export default {
  $on,
  $emit,
  $off,
};

